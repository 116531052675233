.gs-unassignedJobs {
	&__header-div {
		display: flex;
		align-items: flex-end;
		margin-bottom: 2rem !important;

		p {
			margin: 0px;
			padding-right: 10px;
		}

		.gs-unassignedJobs__basic-input {
			margin-right: 20px;
			width: 300px;
		}
	}
}
