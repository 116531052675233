.gs__inline-field {
	margin-bottom: 15px;

	.gs__inline-field__title {
		min-width: 100px;
		margin-right: 25px;
		display: flex;
		align-items: flex-end;
		height: 32px;
	}
	.gs__inline-field__title.gs__phone-number-title {
		min-width: 113px;
	}

	.gs__inline-field__container,
	.MuiTextField-root {
		width: 100%;
	}

	.gs__inline-field__warning {
		font-size: 13px;
		color: red;
	}
}

.gs__icon__edit,
.gs__icon__remove {
	color: #686666;

	&:hover {
		color: red;
	}
}
