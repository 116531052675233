.page-one__fieldset {
	width: 100%;

	legend {
		margin: 10px 0;
	}

	.page-one__option-radio {
		display: flex;
		flex-direction: column;

		.gs_job-checked {
			border-color: #c5332f;
		}

		label {
			border-radius: 10px;
			border: 1px solid;
			margin: 0px;
			margin: 10px 0;
			color: black;
		}
	}
}
