.MuiPaper-root.gs-components__data-table {
	box-shadow: none;
	border-bottom: 1px solid #e0e0e0;

	thead th {
		font-weight: bold;
		border-bottom: 2px solid rgba(224, 224, 224, 1);
	}

	&--no-border {
		border-bottom: 0px;

		thead th,
		th,
		td {
			border: 0px;
		}
	}

	&--edit {
		color: red !important;
	}
}

.gs-components__row-cursor {
	cursor: pointer;
}

.gs-components__data-table--edit {
	padding-right: 0px !important;
	text-align: center !important;
}
