.gs-pagetwo {
	&__header-div {
		button {
			width: 130px;

			&:last-child {
				margin-left: -30px;
			}
		}
	}

	&__btn-category-show {
		z-index: 1;
		transition: background 2s !important;
	}

	&__row-box {
		display: flex;
		align-items: flex-end;
		margin-top: 20px;

		> p {
			width: 25%;
		}

		> div {
			width: 80%;
			display: flex;
			align-items: flex-end;
		}

		.gs-activeJobs__basic-input--description {
			width: 92%;
		}

		.gs-activeJobs__basic-input {
			width: 50%;
		}
	}

	&__text-ml,
	&__text-ml-mr {
		margin-left: 15px !important;
	}

	&__text-mr,
	&__text-ml-mr {
		margin-right: 15px !important;
	}
}
