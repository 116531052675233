.gs-jobPage {
	&__header-div {
		display: flex;
		align-items: flex-end;
		margin-bottom: 0rem !important;

		p {
			margin: 0px;
			padding-right: 10px;
		}

		.gs-jobPage__basic-input {
			margin-right: 20px;
			width: 300px;
		}
	}

	&__date-start-end {
		display: flex;
		align-items: flex-end;
		margin-top: 1em !important;

		p {
			margin: 0px;
			padding-right: 10px;
		}
	}
}
