.add-job-scope__footer-stack {
	button {
		width: 150px;

		&:first-child {
			margin-right: auto;
		}

		&:nth-child(2),
		&:last-child {
			margin-left: auto;
		}
	}
}

.add-job-scope__top-typography {
	display: flex;

	.add-job-scope__close-btn {
		margin-left: auto;
		font-size: 30px;
		cursor: pointer;

		&:hover {
			background-color: rgba(0, 0, 0, 0.04);
		}
	}
}
