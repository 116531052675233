.gs__date-picker--hide {
	display: none !important;
}

.gs__text-field {
	width: 100%;

	.MuiTextField-root,
	.MuiInputBase-root,
	.MuiInputBase-input {
		width: 100% !important;
	}
}
