.MuiAppBar-colorPrimary {
	color: rgb(0, 0, 0) !important;
	background-color: #ffffff !important;
	box-shadow: none !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

ul.MuiList-root:last-child {
	margin-top: auto;
}

.gerp-main__add-job-button {
	position: fixed;
	bottom: 40px;
	right: 24px;

	button {
		background: #c5332f !important;
	}
}

.gs-main {
	&__logo-container {
		display: flex;
		align-items: center;
	}
}

.gs-main__list-active.active {
	background-color: rgba(0, 0, 0, 0.04) !important;
}

.MuiGrid-container.gs-main__user-info-grid {
	margin-left: auto;
	margin-top: auto;
	margin-bottom: auto;
}
