@font-face {
	font-family: 'GTWalsheimPro';
	src: local('GTWalsheimPro'), url(../fonts/GTWalsheimPro-Regular.ttf) format('truetype');
}

// for global use css
@import './global';

// components custom styles
@import './components/data-table.scss';

// pages custom styles
@import './pages/app';
@import './pages/active-jobs';
@import './pages/add-job-scope';
@import './pages/add-job-scope-page-one';
@import './pages/add-job-scope-page-two';
@import './pages/add-job-scope-page-three';
@import './pages/add-job-scope-page-four';
@import './pages/job-page';
@import './pages/unassigned-jobs';
@import './pages/assign-job-page-two';
@import './pages/JobPage/task';
@import './pages/JobPage/document';
