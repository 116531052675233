::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

* {
	font-family: GTWalsheimPro, serif !important;
}

th,
td {
	padding-left: 10px !important;
}

.gs__date-picker-container {
	input {
		padding: 0px;
		padding-bottom: 5px;
		min-width: 178px;
	}

	fieldset {
		border-top: 0px;
		border-right: 0px;
		border-left: 0px;
		border-radius: 0px;
		border-color: #8f8f8f;
	}
}

button.gs-global {
	&__btn {
		border-radius: 20px;
		margin: none;
		width: max-content;
		padding-left: 16px;
		padding-right: 16px;
		cursor: pointer;
		text-transform: capitalize;

		&.MuiButton-outlinedError {
			border: 1px solid #c5332f;
			color: #c5332f;
		}

		&.MuiButton-outlinedSecondary {
			border: 1px solid #636363;
			color: #636363;
		}

		&.MuiButton-outlinedError,
		&.MuiButton-outlinedSecondary {
			background: white;

			&:hover {
				background: white;
			}
		}

		&.MuiButton-containedError {
			background-color: #c5332f;
		}

		&.MuiButton-containedSecondary {
			background-color: #636363;
		}
	}
}

.gs__phone-number-container {
	display: flex;
	align-items: end;

	.gs__phone-number {
		font-size: 16px;
		border-bottom: 2px solid;
		height: 32px;
		width: 100%;

		input {
			border: 0px;
			font-size: 16px;
		}

		input:focus {
			outline: none;
		}
	}
}

.searchbar-container {
	background: whitesmoke;
	height: 0px;
	overflow: hidden;
	border: 0px;
	padding: 0px;
	display: flex;
	align-items: center;
	-webkit-transition: height 0.2s, border 0.2s, padding 0.2s;
	-moz-transition: height 0.2s, border 0.2s, padding 0.2s;
	-ms-transition: height 0.2s, border 0.2s, padding 0.2s;
	-o-transition: height 0.2s, border 0.2s, padding 0.2s;
	transition: height 0.2s, border 0.2s, padding 0.2s;

	.gs-unassignedJobs__header-div {
		margin-bottom: 0px !important;
		margin-right: 20px;

		p {
			font-size: 1rem;
		}
	}

	.gs-container__filter {
		border-radius: 6px;
		border: 1px solid #e0e0e0;
		color: #686666;
		padding-bottom: 10px;
		height: max-content;
	}

	.gs-cpntainer__filter-content {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		span.MuiSwitch-root {
			margin-right: 0px;
			margin-top: 0px;
		}
	}

	&.show {
		height: 144px;
		padding: 18px 11px 32px 11px;
		border-top: 2px solid rgb(224, 224, 224);
		border-bottom: 1px solid rgb(224, 224, 224);
		-webkit-transition: height 0.2s, border 0.2s, padding 0.2s;
		-moz-transition: height 0.2s, border 0.2s, padding 0.2s;
		-ms-transition: height 0.2s, border 0.2s, padding 0.2s;
		-o-transition: height 0.2s, border 0.2s, padding 0.2s;
		transition: height 0.2s, border 0.2s, padding 0.2s;
	}
}

.gerp-logo {
	width: 170px;
	height: auto;

	path:nth-child(6),
	path:nth-child(9) {
		// fill: #c5332f;
	}
}
